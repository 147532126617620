import { startTransition } from "react";
// import ReactGA from "react-ga4";
import { RemixBrowser } from "@remix-run/react";
import { hydrateRoot } from "react-dom/client";

window.extensionScripts = [];

const scripts = [...document.getElementsByTagName("script")];
for (const child of scripts) {
  if (
    child.src.startsWith("chrome-extension:") ||
    child.src.startsWith("moz-extension:")
  ) {
    child.parentElement.removeChild(child);

    window.extensionScripts.push(child);
  }
}

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
  // ReactGA.initialize("G-79X2R2HCZS", { gtagOptions: { send_page_view: true } });
});
